// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preschool-acorns-js": () => import("./../../../src/pages/preschool/acorns.js" /* webpackChunkName: "component---src-pages-preschool-acorns-js" */),
  "component---src-pages-preschool-how-it-works-js": () => import("./../../../src/pages/preschool/how-it-works.js" /* webpackChunkName: "component---src-pages-preschool-how-it-works-js" */),
  "component---src-pages-preschool-maples-js": () => import("./../../../src/pages/preschool/maples.js" /* webpackChunkName: "component---src-pages-preschool-maples-js" */),
  "component---src-pages-preschool-oaks-js": () => import("./../../../src/pages/preschool/oaks.js" /* webpackChunkName: "component---src-pages-preschool-oaks-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-tutoring-highschool-js": () => import("./../../../src/pages/tutoring/highschool.js" /* webpackChunkName: "component---src-pages-tutoring-highschool-js" */),
  "component---src-pages-tutoring-how-it-works-js": () => import("./../../../src/pages/tutoring/how-it-works.js" /* webpackChunkName: "component---src-pages-tutoring-how-it-works-js" */),
  "component---src-pages-tutoring-preschool-to-k-9-js": () => import("./../../../src/pages/tutoring/preschool-to-k9.js" /* webpackChunkName: "component---src-pages-tutoring-preschool-to-k-9-js" */)
}

